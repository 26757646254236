import { SiteEnum } from '@enums/Site.enum';

export const environment = {
  analytics: '',
  cookie: 'somos_sys',
  domain: 'somosimss.org.mx',
  waPhone: '',
  key: 'somos',
  login: {
    title: 'SOMOS',
    description: 'Sociedad Oncológica Multidisciplinaria de Seguridad Social',
  },
  register: {
    recommendations: true,
    public_data: true,
  },
  memberships: {
    no_profile: 1,
    pending: 10,
    transitional: -1,
  },
  association: {
    name: 'SOMOS',
    short_name: 'Sociedad Oncológica Multidisciplinaria de Seguridad Social',
    legal:
      'Sociedad Oncológica Multidisciplinaria de Seguridad Social, SOMOS. 2023',
    congress: '5to Congreso Nacional SOMOS 2023',
  },
  congress: {
    from: '2023-10-28',
    to: '2023-11-07',
    ages: 17,
  },
  works: {
    title:
      'El título debe ser breve y preciso, reflejando de manera concisa el propósito de la investigación, las variables a considerar, la población estudiada y, en caso necesario, el lugar y la fecha de realización, además de mencionar el diseño de investigación.',
    antecedent:
      'Es lo que fundamenta la necesidad de llevar a cabo una investigación con el fin de generar conocimiento que contribuya al cuerpo existente de conocimiento. El planteamiento del problema debe ofrecer un argumento sólido que demuestre que el conocimiento actual es insuficiente para abordar el problema y sus posibles soluciones alternativas, o proporcionar una justificación clara de la necesidad de poner a prueba la veracidad de lo que se considera un hecho irrefutable, debido a nuevos descubrimientos o cambios en las circunstancias.',
    objective: `
    Los objetivos determinan qué aspectos específicos planteados en el problema de investigación se abordarán. La formulación de los objetivos debe ser precisa y clara en cuanto a las variables o categorías a estudiar, las relaciones entre ellas, la población a la que se aplicarán los resultados y, cuando sea relevante, el período de tiempo al que se refieren. Cuando se establecen múltiples objetivos, se pueden distinguir entre un Objetivo General y Objetivos Específicos:

    <br />
    <br />

    <ul>
      <li>
      Objetivo General: Debe expresar de manera explícita lo que se espera lograr con el estudio en términos de conocimiento. Debe proporcionar una visión clara de lo que se pretende describir, determinar, identificar, comparar y verificar.
      </li>

      <li>
      Objetivos Específicos: Representan la descomposición y la secuencia lógica del Objetivo General. Sirven como un adelanto del diseño de la investigación.
      </li>
    </ul>
    `,
    materials: `
    Se detallan los procedimientos y procesos que han llevado a la obtención de los resultados, con el propósito de proporcionar al lector una comprensión precisa de las etapas sucesivas que se han seguido en la realización del trabajo. Por lo general, se incluye información sobre los siguientes aspectos:

    <br />
    <br />

    <ol type="a">
      <li>Tipo de diseño metodológico empleado.</li>

      <li>Descripción de los participantes y el lugar de estudio (o los materiales y el método de adquisición).</li>

      <li>Técnicas utilizadas para recopilar datos.</li>

      <li>Experiencias y observaciones durante el trabajo de campo.</li>

      <li>Métodos empleados para la organización y procesamiento de los datos.</li>

      <li>Proceso de análisis de datos.</li>

      <li>Consideraciones éticas pertinentes.</li>
    </ol>
`,
    results: `En la sección de resultados, se presentan los datos obtenidos siguiendo una secuencia lógica que permite abordar las preguntas u objetivos planteados, con el apoyo habitual de tablas, gráficos u otros elementos visuales. El objetivo es exponer de manera imparcial los principales descubrimientos de la investigación. Esta sección suele incluir diversos tipos de contenido:

    <br />
    <br />

    <ol type="a">
      <li>Texto.</li>

      <li>Tablas.</li>

      <li>Figuras, gráficos o imágenes.</li>
    </ol>
    `,
    discussions: `
    La sección de discusión implica una interpretación y evaluación de los resultados obtenidos, con el propósito de situar el tema estudiado en el contexto general del área de conocimiento. A menudo, es la parte más desafiante de elaborar. Los elementos típicos que se suelen abordar en la discusión son los siguientes:

    <br />
    <br />

    <ol type="a">
      <li>Relacionar los datos obtenidos con descubrimientos previos en la literatura científica.</li>

      <li>Explicar o hacer hincapié en los aspectos más novedosos e importantes del estudio.</li>
      
      <li>Destacar los aspectos más innovadores y significativos del estudio.</li>
      
      <li>Proporcionar recomendaciones o implicaciones prácticas basadas en los resultados.</li>
      
      <li>Identificar y discutir las limitaciones del estudio.</li>
      
      <li>Sugerir nuevas direcciones de investigación.</li>
    </ol>
    `,
    conclusions: `Resumen de los principales descubrimientos del trabajo, los cuales deben abordar los objetivos establecidos al comienzo de este, con el propósito de proporcionar al lector una visión rápida y precisa del valor e interés del estudio.`,
  },
  endpoint: 'https://stage.cms.somosimss.org.mx/api/',
  stripeKey:
    'pk_test_51HFucaD8Us1rDW7Yjq0sMyhp0F3qE2CdQC79ghgz0VMaoijkTWuenXMWrWzMiFEQhpeScxfkRxSORWsinSTMjUx900adI5AU0d',
  production: false,
  site: SiteEnum.Somos,
  webcast:'https://stage.webcast.somosimss.org.mx'
};
